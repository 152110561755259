import { Cell } from './Cell'
import { solution, unicodeSplit } from '../../lib/words'

type Props = {
  guess: string
  className: string
  isRevealing?: boolean
  label: string
}

export const CurrentRow = ({ guess, className, isRevealing, label }: Props) => {
  const splitGuess = unicodeSplit(guess)
  const emptyCells = Array.from(Array(solution.length - splitGuess.length))
  const classes = `flex justify-center mb-1 ${className}`
  const text_classes = `${classes} dark:text-white`

  return (
    <div>
      <p className={text_classes}>{label}</p>
      <div className={classes}>
        {splitGuess.map((letter, i) => (
          <Cell key={i} value={letter} />
        ))}
        {emptyCells.map((_, i) => (
          <Cell key={i} />
        ))}
      </div>
    </div>
  )
}
