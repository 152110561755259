import { getGuessStatuses } from '../../lib/statuses'
import { Cell } from './Cell'
import { unicodeSplit } from '../../lib/words'

type Props = {
  solution: string
  guess: string
  isRevealing?: boolean
  label: string
}

export const Row = ({ solution, guess, isRevealing, label }: Props) => {
  const statuses = getGuessStatuses(solution, guess)
  const splitGuess = unicodeSplit(guess)
  const emptyCells = Array.from(Array(solution.length - splitGuess.length))

  return (
    <div>
      <p className="flex justify-center mb-1 dark:text-white">{label}</p>
      <div className="flex justify-center mb-1">
        {splitGuess.map((letter, i) => (
          <Cell
            key={i}
            value={letter}
            status={statuses[i]}
            position={i}
            isRevealing={isRevealing}
            isCompleted
          />
        ))}
        {emptyCells.map((_, i) => (
            <Cell key={i} />
          ))}
      </div>
    </div>
  )
}
