import { ReactNode } from 'react'
import classnames from 'classnames'
import { REVEAL_TIME_MS } from '../../constants/settings'
import { solution } from '../../lib/words'

type Props = {
  children?: ReactNode
  value: string
  width?: number
  textClass?: string
  onClick: (value: string) => void
  isRevealing?: boolean
}

export const Key = ({
  children,
  width = 43,
  textClass = 'text-xl',
  value,
  onClick,
  isRevealing,
}: Props) => {
  const keyDelayMs = REVEAL_TIME_MS * solution.length

  const classes = classnames(
    `flex items-center justify-center rounded mx-0.5 ${textClass} font-bold cursor-pointer select-none dark:text-white`,
    {
      'transition ease-in-out': isRevealing,
      'bg-slate-200 dark:bg-slate-600 hover:bg-slate-300 active:bg-slate-400': true
    }
  )

  const styles = {
    transitionDelay: isRevealing ? `${keyDelayMs}ms` : 'unset',
    width: `${width}px`,
    height: '58px',
  }

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    onClick(value)
    event.currentTarget.blur()
  }

  return (
    <button
      style={styles}
      aria-label={`${value}`}
      className={classes}
      onClick={handleClick}
    >
      {children || value}
    </button>
  )
}
