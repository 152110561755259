import { unicodeSplit } from './words'

export type CharStatus = 'correct' | 'higher' | 'lower'

export const getGuessStatuses = (
  solution: string,
  guess: string
): CharStatus[] => {

  const splitGuess = unicodeSplit(guess)
  const statuses: CharStatus[] = Array.from(Array(guess.length))

  if (solution > guess)
  {
    splitGuess.forEach((letter, i) => {
      statuses[i] = 'lower'
    })
  }
  else if(solution < guess)
  {
    splitGuess.forEach((letter, i) => {
      statuses[i] = 'higher'
    })
  }
  else if (solution === guess)
  {
    splitGuess.forEach((letter, i) => {
      statuses[i] = 'correct'
    })
  }
  
  return statuses
}
