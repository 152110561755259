import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'
import { MAX_CHALLENGES } from '../../constants/settings'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the word in {MAX_CHALLENGES} tries. 
      </p>
      <br/>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Each guess must be a valid five letter word. Hit the enter button to submit.
      </p>
      <br/>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        After each guess, the color of the tiles will change indicating if the word is higher or lower in the alphabet than your guess.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="A"
          status="lower"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="P"
          status="lower"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="P"
          status="lower"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="L"
          status="lower"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="E"
          status="lower"
        />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The word is lower in the alphabet than the word APPLE.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="Z"
          status="higher"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="O"
          status="higher"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="N"
          status="higher"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="E"
          status="higher"
        />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="S"
          status="higher"
        />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The word is higher in the alphabet than the word ZONES.
      </p>

    </BaseModal>
  )
}
